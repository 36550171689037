<template>
  <!-- 退库申请 -->
  <div class="gateWayWareHoushimg">
    <!-- 板块中心区域 -->
    <div class="gateway_mid">
      <div class="gateway_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item, index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 处理状态 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">处理状态</span>
              <el-select v-model="value1" placeholder="请选择" style="margin:0 1.45vw 0 0">
                <el-option v-for="item in options1" :key="item.id" :label="item.label" :value="item.value1">
                </el-option>
              </el-select>
            </template >
            <!-- 申请设备 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">申请设备</span>
              <el-select v-model="value2" placeholder="请选择">
                <el-option v-for="item in options2" :key="item.id" :label="item.label" :value="item.value2">
                </el-option>
              </el-select>
            </template>
          </div>
        </div>
      </div>

      <!-- 表格区域 -->
      <div class="gateway_form">
        <!-- 列表页 -->
        <lists></lists>
      </div>

      <!-- 公司的基本信息 -->
      <div class="firm_inform">
        
      </div>
    </div>
  </div>
</template>

<script>
// 引入列表页
import lists from "./components/lists";
// 引入vuex的辅助函数
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
export default {

  components: {
    lists,//列表页
  },

  data () {
    return {
      value1:'',//处理状态
      value2:'',//申请设备
    };
  },
  computed: {
    ...mapState('withDrawalApplication',['options1','options2'])
  
  },
  mounted () {
    
  },

  methods: {

  },
};
</script>

<style  scoped>
.gateWayWareHoushimg {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.gateWayWareHoushimg .gateway_mid {
  width: 82.5vw;
  height: 85vh;
  margin-top: -5px !important;
}

/* 头部--开始 */
.gateway_mid .gateway_header {
  width: 100%;
  height: 134px;
  background-color: #fff;
}

.gateway_header .title {
  display: flex;
  align-items: flex-end;
}

.gateway_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}

.gateway_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}

/* 选项框和按钮 */
.option_btn {
  width: 100%;
  height: 7.2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}

.options {
  height: 7.2vh;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}

.option_btn span {
  font-size: 16px;
  font-family: 'SHSCN_Regular';
}

/* 头部--结束 */

/* 表格区域--开始 */
.gateway_mid .gateway_form {
  width: 100%;
  height: 75vh;
  background-color: #fff;
  margin-top: 1vh;
}

/* 表格区域--结束 */
.lists /deep/ .el-pagination {
  align-items: normal;
  margin-top: 14px;
  height: 50px !important;
}

/* 表格区域--结束 */
/* 屏幕的分辨率大于1920 */
@media screen and (min-width: 1920px) {
  .gateWayWareHoushimg .gateway_mid {
    width: 82.5vw;
    height: 85vh;
  }

  .gateway_mid .gateway_form {
    width: 100%;
    height: 70vh;
    background-color: #fff;
    margin-top: 1vh;
    /* overflow-y:scroll; */
  }

  /* 公司的基本信息 */
  .firm_inform {
    width: 100%;
    height: 40px;
    background-color: #fff;
    margin-top: 21.5px;
  }

  .lists /deep/ .el-pagination {
    align-items: normal;
    margin: 14px 20px 0 0;
  }
}

@media screen and (max-width: 1680px) {

  /* 公司的基本信息 */
  .firm_inform {
    width: 100%;
    height: 30px;
    position: relative;
    top: 116px;
    background-color: #fff;
  }
}</style>