<template>
  <!-- 未处理完--详情页 -->
  <div class="noFinish">
    <el-dialog title="退库详情" :visible.sync="detailObj.isshow2" width="47.14%"
      :close-on-click-modal="false">
      <!-- 退库申请编号 -->
      <div class="withDrawalNumber">
        <h4>退库申请编号:&nbsp;&nbsp;&nbsp;&nbsp;{{applicateInfo.numbering}}</h4>
        <div class="content">
          <p>
            申请人&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{
              applicateInfo.applicant
            }}</span>
          </p>
          <p>
            申请日期<span>{{ applicateInfo.applicateData }}</span>
          </p>
          <p>
            申请设备<span>{{ applicateInfo.applicateDevice }}</span>
          </p>
          <p>网关编号<span>123FR4G156</span></p>
          <p>
            申请说明<span>{{ applicateInfo.withDrawalCause }}</span>
          </p>
          <p>
            处理状态
            <span>
              <img src="../../../../assets/image/withDrawalApplication_slices/elliptical_27.png" alt="" style="margin-right:5px"/>处理中
            </span>
          </p>
        </div>
      </div>
      <!-- 审批意见 -->
      <div class="applicateOpinion">
        <h4>审批意见</h4>
        <div class="opinionIpt">
          <template>
            <el-radio v-model="radio" label="1" text-color="#171717"
              >同意</el-radio>
            <el-radio v-model="radio" label="2" text-color="#171717"
              >不同意,驳回</el-radio>
          </template>
        <div class="opinionTexteare">
          <el-input type="textarea" placeholder="请输入审批内容" v-model="textarea" maxlength="200" show-word-limit style="width: 100%;">
          </el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="detailObj.isshow2 = false">确 定</el-button>
        </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
export default {
  props: ['detailObj', 'applicateInfo'],
  data() {
    return {
      radio:'1',//退库申请--审批意见
      textarea:''
    }
  },
  mounted() {},

  methods: {},
}
</script>

<style scoped>
/* 退库申请编号 */
.withDrawalNumber h4 {
  font-size: 16px;
  font-family: 'SHSCN_Regular';
  color: #171717;
  margin-left: 1vw;
}
.withDrawalNumber p {
  font-size: 14px;
  font-family: 'SHSCN_Regular';
  color: #ababab;
  line-height: 60px;
}
.withDrawalNumber span {
  font-size: 14px;
  font-family: 'SHSCN_Regular';
  color: #171717;
  margin-left: 1.5vw;
}
.content {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin:1vh 0 0 2vw;
}

/* 审批意见 */
.applicateOpinion{
  margin: 1.5vh 0 0 1vw;
}
/* 添加伪类  */
.applicateOpinion h4::before {
  content: '*';
  color: #df2626;
}
.applicateOpinion h4 {
  font-size: 16px;
  font-family: 'SHSCN_Regular';
  color: #171717;
}
/* 文本域 */
.el-textarea /deep/ .el-textarea__inner{
  width: 85%;
  height: 13.8vh;
  resize: none;
  margin-top: 2vh;
}
.opinionIpt{
  margin: 2.9vh 0 0 1vw;
  font-family: 'SHSCN_Regular';
}
.el-textarea /deep/ .el-input__count{
  right: 16%;
  top: 85%;
}
.dialog-footer{
  position: relative;
  left: 85%;
  margin-top: 3vw;
}
/* 页面小于1500px时  出现滑轮 */
@media screen and (max-width: 1500px) {
   ::v-deep .el-dialog{
    overflow-x: scroll;
    width: 50% !important;
   }
}
</style>
