<template>
  <!-- 退库申请-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName" :default-sort="{prop: 'date', order: 'descending'}" :header-cell-style="{background:'#4888FF'}" height="600">
        <!-- 索引序号 -->
        <el-table-column type="index" width="100" fixed></el-table-column>
        <!-- 退库申请编号 -->
        <el-table-column prop="numbering" label="退库申请编号"  min-width="200">
        </el-table-column>
        <!-- 状态 -->
        <el-table-column prop="status" label="状态" min-width="200">
          <template slot-scope="scope">
            <!-- 0--闭环 1--驳回 2--处理中 -->
            <div class="applicateStatus" v-if="scope.row.status == 0">
              <img src="../../../assets/image/withDrawalApplication_slices/path_2011.png" style="padding:0 3px 0 0;margin: 0 0 0 -12px;"/>闭环
            </div>
            <div class="applicateStatus" v-else-if="scope.row.status == 1">
              <img src="../../../assets/image/withDrawalApplication_slices/path_2013.png" alt="" style="padding:0 3px 0 0;margin: 0 0 0 -12px;"/>驳回
            </div>
            <div class="applicateStatus" v-else>
              <img src="../../../assets//image/withDrawalApplication_slices/elliptical_27.png" alt="" style="padding:0 3px 0 0;"/>处理中
            </div>
          </template>
        </el-table-column>
        <!-- 申请人 -->
        <el-table-column prop="applicant" label="申请人" min-width="200">
        </el-table-column>
        <!-- 申请日期 -->
        <el-table-column prop="applicateData" label="申请日期" sortable min-width="200">
        </el-table-column>
        <!-- 申请设备 -->
        <el-table-column prop="applicateDevice" label="申请设备" min-width="200"></el-table-column>
        <!-- 退库原因 -->
        <el-table-column prop="withDrawalCause" label="退库原因" min-width="200">
        </el-table-column>
        <!-- 处理人 -->
        <el-table-column prop="handlers" label="处理人" min-width="200">
        </el-table-column>
        <!-- 处理结果 -->
        <el-table-column prop="dealResult" label="处理结果" min-width="200"> 
          <template slot-scope="scope">
            <!-- 同意 -->
           <p class="result" v-if="scope.row.status == 0">同意</p>
           <!-- 不同意 -->
           <p class="result" v-else-if="scope.row.status == 1">不同意</p>
           <!-- 处理中 -->
           <p class="result" v-else>--</p>
          </template>
        </el-table-column>
        <!-- 处理日期 -->
        <el-table-column prop="dealData" label="处理日期" min-width="200">
          <!-- 0--闭环 1--驳回  0和1有处理时间   2--处理中 没有处理时间 -->
          <template slot-scope="scope">
            <p v-if="scope.row.status == 2">{{scope.row.dealData}}</p>
            <p v-else>{{scope.row.dealData}}</p>
          </template>
        </el-table-column>
        <!-- 详情 -->
        <el-table-column label="详情" width="100" fixed="right"> 
          <template slot-scope="scope">
            <!-- 0--闭环 1--驳回  0和1已经处理完成   2--处理中 正在处理 -->
            <el-link type="primary"
             @click="noFinish(scope.row)"
             v-if="scope.row.status == 2">详情</el-link>
            <el-link type="primary" @click="finish(scope.row)"
            v-else>详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="currentPage" :page-sizes="[10]" :page-size="100" layout="total, prev, pager, next,sizes,jumper"
        :total="100">
      </el-pagination>
    </template>
    <!-- 注册forms1和forms2 -->
    <forms1 :detailObj="detailObj" :applicateInfo="applicateInfo"></forms1>
    <forms2 :detailObj="detailObj" :applicateInfo="applicateInfo"></forms2>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
// 引入详情页面1
import forms1 from "./details/forms1";
// 引入详情页面2
import forms2 from "./details/forms2";
export default {
  components: {
    forms1,//当status为0或1时，表示处理完成,展示表单1
    forms2,//当status为2时，表示未处理,展示表单2
  },

  data () {
    return {
      applicateInfo:{},//退库申请详情信息
    };
  },
  computed: {
    ...mapState('withDrawalApplication',['tableData','currentPage','detailObj',]),
  },
  mounted () {

  },

  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    // 未完成
    noFinish(info){
      this.detailObj.isshow2 = true;
      this.applicateInfo = {...info};
    },
    // 已完成
    finish(info){
      this.detailObj.isshow1 = true;
      this.applicateInfo = {...info};
    }
  },
};
</script>

<style  scoped>

.el-table /deep/ .warning-row {
  background: #fff;
}
::v-deep .el-dialog__title{
  font-family: 'SHSCN_Bold';
}
.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-weight: normal;
  font-size: 14px;
}
.lists .el-pagination {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
/* 状态 */
.el-table-column .applicateStatus{
  font-size: 14px;
  font-family: 'SHSCN_Regular';
  word-spacing: 1px;
}
.el-table-column .applicateStatus img{
  width: 8px;
  height: 8px;
  padding-right: 5px;
}
.el-table /deep/ .el-table__cell{
  text-align: center;
  padding: 16px 0;
}
</style>