<template>
  <!-- 已经处理完成--详情页--驳回/闭环 -->
  <div class="finished">
    <!-- Element ui的对话框 -->
    <el-dialog title="退库详情" :visible.sync="detailObj.isshow1" width="47.14%" :close-on-click-modal="false">
      <div class="forms1_left">
        <h4>退库申请编号：{{applicateInfo.numbering}}</h4>
        <div class="left_content">
          <!-- 申请人 -->
          <p>申请人&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{applicateInfo.applicant}}</span></p>
          <!-- 申请日期 -->
          <p>申请日期<span>{{applicateInfo.applicateData}}</span></p>
          <!-- 申请设备 -->
          <p>申请设备<span>{{applicateInfo.applicateDevice}}</span></p>
          <!-- 网关编号 -->
          <p>网关编号<span>123FR4G156</span></p>
          <!-- 申请说明 -->
          <p>申请说明<span>{{applicateInfo.withDrawalCause}}</span></p>
          <!-- 处理状态 -->
          <p class="status">
            处理状态
            <span v-if="applicateInfo.status == 0">
              <!-- 0--闭环 1--驳回 2--处理中 -->
              <img src="../../../../assets/image/withDrawalApplication_slices/path_2011.png" alt="" style="margin-right:5px">闭环
            </span>
            <span v-else-if="applicateInfo.status == 1">
              <img src="../../../../assets/image/withDrawalApplication_slices/path_2013.png" alt="" style="margin-right:5px">驳回
            </span>
          </p>
        </div>
      </div>
      <div class="forms1_right">
        <h4>审批意见</h4>
        <p>审批结果
          <span v-if="applicateInfo.status == 0">同意,通过</span>
          <span v-else>不同意,驳回</span>
        </p>
        <p>审批时间<span>{{applicateInfo.dealData}}</span></p>
        <p>审批意见<span>未出现描述原因</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  props:["detailObj",'applicateInfo'],
  data() {
    return {}
  },
  computed: {
    
  },
  mounted() {},

  methods: {},
}
</script>

<style scoped>
  ::v-deep .el-dialog__body{
    display: flex;
  }
  .finished /deep/ .el-dialog{
    margin-top: 10vh;
    width: 50% !important;
    height: 60% !important;
   }
  /* dialog--title */
  ::v-deep .el-dialog__title{
    font-family: 'SHSCN_Bold';
  }
  /* 退库申请编号和审批意见 */
  .forms1_left,.forms1_right{
    width: 50%;
  }
  .el-dialog__body h4{
    font-size: 16px;
    font-family: 'SHSCN_Regular';
    margin-left:1.875vw;
    color: #171717;
  }
  /* 退库申请内容区域 */
  /* 左边区域 */
  .left_content{
    margin-left: 1vw;
  }
  .left_content p{
    font-size: 14px;
    font-family: 'SHSCN_Regular';
    color: #ABABAB;
    margin:2.1vw 0 0 1.875vw;
  }
  .left_content p span{
    font-size: 14px;
    font-family: 'SHSCN_Regular';
    color: #171717;
    margin-left: 2.7vw;
  }
  .left_content{
    display: flex;
    flex-direction: column;
  }
  /* 右边区域 */
  .forms1_right{
    border-left: 1px dashed #ABABAB;
    padding-left: 4vw;
  }
  .forms1_right p{
    font-size: 14px;
    font-family: 'SHSCN_Regular';
    color: #ABABAB;
    margin:2.1vw 0 0 3vw;
  }
  .forms1_right p span{
    font-size: 14px;
    font-family: 'SHSCN_Regular';
    color: #171717;
    margin-left: 2vw;
  }
  /* 页面小于1500px时  出现滑轮 */
@media screen and (max-width: 1500px) {
  .finished /deep/ .el-dialog{
    overflow-x: scroll;
    width: 40% !important;
   }
}
</style>
